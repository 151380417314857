import { ReactComponent as Plus } from './icons/plus.svg'
import { ReactComponent as Trash } from './icons/trash.svg'
import { ReactComponent as ZoomIn } from './icons/zoomIn.svg'
import { ReactComponent as ZoomOut } from './icons/zoomOut.svg'
import { ReactComponent as Clipboard } from './icons/clipboard.svg'
import { ReactComponent as ClipboardCheck } from './icons/clipboardCheck.svg'

function Button({ text, onClick, type, children, className, info, setInfo }) {

  function onClickTracked() {
    onClick();
  }

  return (
      <button
        onClick={onClickTracked}
        className={`${className} flex focus:outline-none items-center text-s border rounded-md font-medium border-gray-300 text-gray-600 shadow hover:shadow-md transition-shadow duration-150 select-none`}
        onMouseEnter={() => setInfo && setInfo(info)}
        onMouseLeave={() => setInfo && setInfo('')}
      >
        {type === 'plus' && <span className={text && 'pr-1'}><Plus/></span>}
        {type === 'trash' && <span className={text && 'pr-1'}><Trash/></span>}
        {type === 'zoomIn' && <span className={text && 'pr-1'}><ZoomIn/></span>}
        {type === 'zoomOut' && <span className={text && 'pr-1'}><ZoomOut/></span>}
        {type === 'clipboard' && <span className={text && 'pr-1'}><Clipboard/></span>}
        {type === 'clipboardCheck' && <span className={text && 'pr-1'}><ClipboardCheck/></span>}
        <span>{text}</span>
        <span>{children}</span>
      </button>
  )
}

export default Button;
