import React from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify';

const defaultPresets = [
  { value: 'Standard',  label: 'Standard' },
];

const customPresets = [
  { value: 'New', label: 'User' },
];

const groupedPresets = [
  {
    label: 'Default',
    options: defaultPresets,
  },
  {
    label: 'User',
    options: customPresets,
  },
];

const colourStyles = {
  control: styles => ({ ...styles, fontSize: '0.875rem', fontWeight: '500', color: '#4b5563' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '0.875rem',
      fontWeight: '500',
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#e2f1f9'
          : isFocused
            ? '#f5f9f9'
            : null,
      color: '#4b5563',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && '#c0dff3',
      },
    };
  },
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '100%',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 11,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.19em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

function ModelSelect({ onModelSelect, modelSelected, className }) {
  if(!modelSelected) modelSelected = groupedPresets[0].options[0];

  function onChange(data) {
    if(!window.localStorage.getItem('tensorflowjs_models/my-model/info') && data.value === 'New') {
      toast('Train and save first your own engine on the Training tab.', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      onModelSelect(data)
    }
  }

  return (
    <Select
      className={`z-20 ${className}`}
      value={modelSelected}
      options={groupedPresets}
      formatGroupLabel={formatGroupLabel}
      maxMenuHeight='600px'
      placeholder='Engine'
      styles={colourStyles}
      onChange= {data => onChange(data)}
    />
  )
}

export default ModelSelect;