import { Line } from 'react-chartjs-2';
import ReactGA from 'react-ga';
import chroma from "chroma-js";

let options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  animation: false,
  elements: {
    line: {
      fill: false,
    },
    point: {
      borderWidth: 0,
      hoverBorderWidth: 0,
      hitRadius: 0
    }
  },
  scales: {},
  plugins: {
    annotation: {
      annotations: []
    },
    tooltip: {
      boxWidth: 40,
      boxHeight: 40,
      mode: 'nearest',
      intersect: true,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      bodyColor: '#fff',
      callbacks: {},
    },
    legend: {
      display: false,
    },
    title: {
      text: 'Hue',
      display: true,
      position: 'left',
      color: 'black',
      font: {
        weight: 'bold',
        family: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      }
    },
    dragData: {
      showTooltip: true,
      magnet: {
        //to: (value) => Math.round(value / 10) * 10,
      },
      onDrag: (e, datasetIndex, index, value) => {
        //
      },
      onDragStart: function (e, element) {
        e.target.style.cursor = 'grabbing';
      },
      onDragEnd: function (e, datasetIndex, index, value) {
        ReactGA.event({category: 'drag', action: 'hue dragged', label: 'family:' + datasetIndex + ' color:' + index});
      },
    },
  },
};

function Hue({ palette, onDrag, zoom, chartsCallback }) {
  let soloFamilies = palette.filter(family => family.solo === true);
  let soloFamiliesCount = soloFamilies.length;

  let div = 2;
  options.plugins.annotation.annotations = [];
  if(soloFamiliesCount) soloFamilies[0].colors.forEach((color, key) => {
    for(let i = 0; i < 360/div; i++){
      let sample = chroma.lch(color.lch[0], color.lch[1], i*div)

      if(sample.clipped()) options.plugins.annotation.annotations.push({
        type: 'box',
        yMin: i*div,
        yMax: (i + 1)*div,
        xMin: key - 0.5,
        xMax: key + 0.5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 0
      });
    }
  });

  options.plugins.tooltip.callbacks = {
    labelColor: function(context) {
      return {
        backgroundColor: palette[context.datasetIndex].solo || soloFamiliesCount === 0 ? palette[context.datasetIndex].colors[context.dataIndex].hex : 'rgba(0,0,0,0)',
      }
    },
    label: function(context) {
      if(palette[context.datasetIndex].solo || soloFamiliesCount === 0) return ' ' + context.raw.toFixed(1) + ' '; // show label if single solo family or no solo families at all.
    },
    title: function(context) {
      return ''
    }
  };

  options.plugins.dragData.onDrag = (e, datasetIndex, index, value) => {
    onDrag(e, datasetIndex, index, value)
  };

  options.onHover = (e, point, chart) => {
    // show grab over point when belongs to a solo family or when no solos at all.
    point.length > 0 && (palette[point[0].datasetIndex].solo || soloFamiliesCount === 0) ? e.native.target.style.cursor = 'grab' : e.native.target.style.cursor = 'default';
  };

  options.scales = {
    x: zoom.x.active ? zoom.x : undefined,
    y: {
      max: zoom.x.active ? undefined : 360,
      min: zoom.x.active ? undefined : 0,
      ticks: {
        stepSize: zoom.x.active ? 1 : 40
      }
    }
  };

  const data = canvas => {
    return {
      labels: ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
      datasets: palette.map((family) => {
        let gradient = zoom.x.active ? family.zoomGradient : family.gradient;
        return {
          label: family.id,
          data: family.colors.map((color) => {
            return color.lch[2]
          }),
          pointRadius: 4,
          hoverRadius: 4,
          dragData: family.solo || soloFamiliesCount === 0, // drag for a solo or when no solos
          backgroundColor: family.solo || soloFamiliesCount === 0 ? family.colors.map((color) => {
            return color.hex
          }) : 'rgba(0, 0, 0, 0)',
          borderColor: family.solo || soloFamiliesCount === 0 ? gradient : 'rgba(0, 0, 0, 0.1)',
        }
      })
    };
  };

  return (
    <div className='relative w-full' style={{height: '30vh'}}>
      <Line ref={chartsCallback} data={data} options={options} />
    </div>
  )
}

export default Hue;
