import chroma from 'chroma-js';

class Color
{
  constructor(familyIndex = '', index, hex = null, lch = null)
  {
    this.index = parseInt(index);
    this.familyIndex = parseInt(familyIndex);
    this.hex = hex;
    this.lch = lch;

    if(!lch && hex) this.lch = chroma(hex).lch();
    if(lch && !hex) this.hex = chroma.lch(lch).hex();

    this.dragging = false;
    this.id = familyIndex.toString() + index;
  }

  hidrate(color)
  {
    this.id = color.id;
    this.familyIndex = color.familyIndex;
    this.index = color.index;
    this.hex = color.hex;
    this.lch = color.lch;
  }

  setLuminosity(luminosity)
  {
    this.lch[0] = luminosity;

    let newColor = chroma.lch(this.lch);
    this.hex = newColor.hex();
    this.lch = newColor.lch();

    return this;
  }

  setChroma(chromaValue)
  {
    this.lch[1] = chromaValue;

    let newColor = chroma.lch(this.lch);
    this.hex = newColor.hex();
    this.lch = newColor.lch();
    return this;
  }

  setHue(hue)
  {
    this.lch[2] = hue;

    let newColor = chroma.lch(this.lch);
    this.hex = newColor.hex();
    this.lch = newColor.lch();

    return this;
  }
}

export default Color;
