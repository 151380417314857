import MobileMockup from './MobileMockup'
import MobileMockupDark from './MobileMockupDark'
import { useState, useEffect } from 'react'
import { ReactComponent as CheckIcon } from './icons/check.svg'
import ReactGA from 'react-ga';

function Context({ palette }) {
  const [ primaryFamily, setPrimaryFamily ] = useState(palette[0]); // TODO when a primary is modified in the picker, the mockup does not update.
  const [ dynamicColors, setDynamicColors ] = useState(sortColors());

  useEffect(() => {
    setDynamicColors(sortColors())
  }, [palette, primaryFamily]);

  function sortColors(){
    let sortedColors = [];
    let offset = primaryFamily.datasetIndex;

    for(let i = offset; i < 5 + offset; i++){
      let carrierIndex = i%palette.length;

      sortedColors.push(palette[carrierIndex].colors)
    }

    return sortedColors;
  }

  function onSelectPrimary(family) {
    setPrimaryFamily(family);

    ReactGA.event({category: 'button', action: 'set primary', label: family.datasetIndex});
  }

  return (
      <div className='p-3 flex flex-col text-gray-900 font-medium'>
        <h2 className='mb-3'>Select primary</h2>
        <div className='flex mb-3'>
          {palette.map((family, key) => {
            return (
            <div
              key={key}
              className={`${primaryFamily.id === family.id && 'border-4'} h-8 w-8 m-1 rounded cursor-pointer flex items-center justify-center`}
              onClick={() => {onSelectPrimary(family)}}
              style={{backgroundColor: family.colors[4].hex, borderColor: family.colors[2].hex}}
            >
              {(primaryFamily.id === family.id) &&
                <CheckIcon stroke={family.colors[0].hex} strokeWidth='4' />
              }
            </div> )
          })}
        </div>
        <div className='block sm:flex'>
          <div className='mr-8'>
            <MobileMockup dynamicColors={dynamicColors}/>
          </div>
          <MobileMockupDark dynamicColors={dynamicColors}/>
        </div>
      </div>
  );
}

export default Context;
