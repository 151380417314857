import * as tf from '@tensorflow/tfjs'
import * as tfvis from "@tensorflow/tfjs-vis";
import Button from './Button';
import { ChromePicker } from 'react-color';
import chroma from 'chroma-js'
import { useState, useEffect } from 'react';
import { decodePalette } from './../components/utilities/palette'
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

function Training({ palette, presets, loadModelFromLocalStorage }) {
  const [predicted, setPredicted] = useState([]);
  const [trained, setTrained] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [epochs, setEpochs] = useState(100);
  const [pickedColor, setPickedColor] = useState();
  const [displayDataset, setDisplayDataset] = useState([]);
  const [datasetSelected, setDatasetSelected] = useState([]);

  useEffect(() => {
    if(isTraining) train();
  }, [isTraining]);

  useEffect(() => {
    setDisplayDataset(adaptDataForDisplay())
  }, [datasetSelected]);

  function notify(text){
    toast(text, {
      position: 'bottom-left',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function adaptDataForDisplay() {
    let y = [];

    datasetSelected.forEach((palette) => {
      let decodedPalette = decodePalette(palette.data);

      decodedPalette.forEach((family) => {
        let out = [];
        family.colors.forEach((color) => {
          out = [...out, color.hex];
        });
        y.push(out)
      });
    });

    return y;
  }

  function adaptData() {
    let x = [];
    let y = [];

    datasetSelected.forEach((palette) => {
      let decodedPalette = decodePalette(palette.data);

      decodedPalette.forEach((family) => {
        let out = [];
        family.colors.forEach((color) => {
          if(isNaN(color.lch[0])) color.lch[0] = 0;
          if(isNaN(color.lch[1])) color.lch[1] = 0;
          if(isNaN(color.lch[2])) color.lch[2] = 0;
          let rgb = chroma(color.hex).rgb();
          out = [...out, rgb[0]/255, rgb[1]/255, rgb[2]/255];
          x.push([rgb[0]/255, rgb[1]/255, rgb[2]/255])
        });

        family.colors.forEach(() => {
          y.push(out)
        })
      });
    });

    return [x, y]
  }

  const train = () => {
    const [x, y] = adaptData();

    if(x.length === 0) {
      notify('Select at least one dataset.');
      setIsTraining(false);
      return;
    }
    const DATA = tf.tensor(x);

    const nextDayInfections = tf.tensor(y);

    const model = tf.sequential({ // 3 6
      layers: [
        tf.layers.dense({
          inputShape: [DATA.shape[1]],
          units: 3,
          activation: 'sigmoid',
        }),
        tf.layers.dense({
          inputShape: [DATA.shape[1]],
          units: 6,
          activation: 'sigmoid',
        }),
        tf.layers.dense({
          units: 30,
          activation: 'sigmoid',
        })
      ]
    });

    tfvis.visor().open();

    model.compile({
      optimizer: tf.train.adam(0.1),
      loss: 'meanSquaredError',
      metrics: ['mse']
    });

    const fitCallbacks = tfvis.show.fitCallbacks({ name: 'show.fitCallbacks', tab: 'Training', styles: {height: '1000px'} }, ['loss', 'val_loss'] );

    model.fit(DATA, nextDayInfections, {
      shuffle: true,
      epochs: epochs,
      validationSplit: 0.2,
      batchSize: 400,
      callbacks: fitCallbacks,
    }).then(() => {
      setIsTraining(false);
      setTrained(model);

      setPickedColor('#3C6AFB');
      let prediction = model.predict(tf.tensor([[60/255, 106/255, 256/255]])).dataSync();

      setPredicted(predictedToHex(prediction))
    });

    setIsTraining(true);

    ReactGA.event({category: 'button', action: 'train', label: epochs});
  };

  //-------------------------------------------------

  function predictedToHex(predicted) {
    let predictedColors = [];
    let singleColor = [];

    [...predicted].forEach((value, index) => {
      singleColor.push(value);
      if(index%3 === 2) {
        predictedColors.push(chroma(singleColor[0]*255, singleColor[1]*255, singleColor[2]*255, 'rgb').hex());
        singleColor = [];
      }
    });

    return predictedColors;
  }

  function onColorChange(color) {
    setPickedColor(color.hex);
    let prediction = trained.predict(tf.tensor([[color.rgb.r/255, color.rgb.g/255, color.rgb.b/255]])).dataSync();

    setPredicted(predictedToHex(prediction))
  }

  function onDatasetSelect(e) {
    let selected = [...presets[0].options, ...presets[1].options].filter(preset => preset.value === e.target.value)[0];

    if(datasetSelected.includes(selected)) {
      let newSelected = [...datasetSelected].filter(dataset => dataset !== selected);
      setDatasetSelected(newSelected);
      return;
    }

    setDatasetSelected([...datasetSelected, selected]);

    ReactGA.event({category: 'button', action: 'select dataset'});
  }

  function save() {
    notify('Engine saved. Now you can use it on the palette editor.');
    trained.save('localstorage://my-model').then(() => {
      loadModelFromLocalStorage()
    });

    ReactGA.event({category: 'button', action: 'save model'});
  }

  function dataset() {
    let [x , y] = adaptData();

    let outputX = '';
    let outputY = '';

    x.forEach(row => {
      row.forEach(value => {
        outputX += value + ','
      });
      outputX += '\n'
    });

    console.log(outputX)

    y.forEach(row => {
      row.forEach(value => {
        outputY += value + ','
      });
      outputY += '\n'
    });

    console.log(outputY)
  }

  return (
    <div className='p-3'>
      <div className='flex'>
        <div>
          <div className='text-xs text-gray-500 tracking-wider mb-1 font-medium select-none'>DATASETS</div>
          <div className='flex text-sm text-gray-800'>
            <div className='mr-3'>
              <div className='text-sm text-gray-700 font-medium pb-1 select-none'>Default</div>
              {presets[0].options.map((preset, key) => (
                <div key={key}>
                  <label className='cursor-pointer select-none flex items-center'>
                    <input type="checkbox" value={preset.value} onChange={onDatasetSelect} className='mr-1'/>
                    {preset.label}
                  </label>
                </div>
              ))}
            </div>
            <div>
              <div className='text-sm text-gray-700 font-medium pb-1 select-none min-w-[100px]'>User</div>
              {presets[1].options.map((preset, key) => (
                <div key={key}>
                  <label className='cursor-pointer select-none flex items-center'>
                    <input type="checkbox" value={preset.value} onChange={onDatasetSelect} className='mr-1'/>
                    {preset.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='ml-4'>
          <div className='text-xs text-gray-500 tracking-wider mb-2 font-medium select-none'>TRAINING DATA</div>
          {
            displayDataset.map((family, familyKey) => (
              <div className='flex' key={familyKey}>{
                family.map((color, colorKey) => (
                  <div className='h-1 w-2' key={colorKey} style={{backgroundColor: color}}></div>
                ))
              }</div>
            ))
          }
        </div>
      </div>

      <div className='text-xs text-gray-500 tracking-wider mb-1 mt-4 font-medium select-none'>TRAINING SETTINGS</div>
      <div className='flex'>
        <div className='flex flex-col'>
          <label className='text-sm text-gray-700 font-medium pb-1 select-none'>Epochs</label>
          <div className='flex'>
            <input type="number" value={epochs} onChange={(e) => setEpochs(e.target.value)} className='border px-2 py-1 mb-3 rounded'/>
            <Button className={`py-1 px-2 text-sm mb-3 ml-2 ${isTraining && 'cursor-not-allowed'}`} onClick={() => {setIsTraining(true)}}  disabled={isTraining}>
              { isTraining ? 'Training...' : 'Train'}
            </Button>
          </div>
        </div>
      </div>
      <Button className='py-1 px-2 text-sm mb-3' onClick={dataset}>Dataset</Button>
      <div className='text-xs text-gray-500 tracking-wider mb-1 mt-3 font-medium select-none'>ENGINE PREVIEW</div>
      {
        trained && !isTraining ?
        <div>
          <Button className='py-1 px-2 text-sm mb-3' onClick={save}>Save engine</Button>
          <ChromePicker
            className='border'
            color={pickedColor}
            onChange={(color) => onColorChange(color)}
            disableAlpha={true} />

          <div className='flex mt-3'>
            {
              predicted.map((color, index) => (
                <div key={index} className='w-16 h-16 rounded-md mr-1' style={{backgroundColor: color}}></div>
              ))
            }
          </div>
        </div>
          :
          <div className='text-xs'>No training yet.</div>
      }
    </div>
  );
}

export default Training;
