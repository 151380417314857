import chroma from 'chroma-js';
import APCAcontrast from '../utilities/APCA';

class Family
{
  constructor(id, name, colors = [], options)
  {
    this.id = id;
    this.name = name;
    this.colors = colors;
    this.contrasts = {
      overBlack: [],
      overWhite: [],
      pairs: []
    };

    this.contrastModel = (options && options.contrastModel) ||'wcag';
    this.solo = false;
    this.datasetIndex = 0;
    this.pickedColor = '#0000ff';
    this.forceColor = false;
    this.hasBeenDragged = false;

    this.gradient = null;
    this.zoomGradient = null;
    this.gradientWidth = null;

    this.update()
  }

  update(options)
  {
    if(!this.colors) return;

    let defaults = {contrastModel: this.contrastModel};
    let params = {...defaults, ...options};

    this.updateGradient();
    this.contrasts.overBlack = [];
    this.contrasts.overWhite = [];
    this.contrasts.pairs = [];

    this.colors.forEach((color) => {
      if(params.contrastModel === 'wcag') this.contrasts.overBlack.push(chroma.contrast('#000000', color.hex));
      if(params.contrastModel === 'apca') this.contrasts.overBlack.push(APCAcontrast('0x000000', '0x' + color.hex.substring(1)));

      if(params.contrastModel === 'wcag') this.contrasts.overWhite.push(chroma.contrast('#ffffff', color.hex));
      if(params.contrastModel === 'apca') this.contrasts.overWhite.push(APCAcontrast('0x' + color.hex.substring(1), '0xffffff'));

      // if(params.contrastModel === 'wcag') this.contrasts.overBlack.push(chroma.contrast(this.colors[9].hex, color.hex));
      // if(params.contrastModel === 'apca') this.contrasts.overBlack.push(APCAcontrast('0x' + this.colors[9].hex.substring(1), '0x' + color.hex.substring(1)));
      //
      // if(params.contrastModel === 'wcag') this.contrasts.overWhite.push(chroma.contrast(this.colors[0].hex, color.hex));
      // if(params.contrastModel === 'apca') this.contrasts.overWhite.push(APCAcontrast('0x' + color.hex.substring(1), '0x' + this.colors[0].hex.substring(1)));
    });

    let hexColors = ['#ffffff' ,...this.colors.map(color => color.hex), '#000000'];

    hexColors.forEach((color, key) => {
      if(hexColors[key + 1]) {
        if(params.contrastModel === 'wcag') this.contrasts.pairs.push(chroma.contrast(color, hexColors[key + 1]));
        if(params.contrastModel === 'apca') this.contrasts.pairs.push(APCAcontrast('0x' + hexColors[key + 1].substring(1), '0x' + color.substring(1)))
      }
    });
  }

  updateGradient(width)
  {
    if(this.colors.length === 0) return;

    if(width) this.gradientWidth = width;
    //console.log(this.gradientWidth, width)

    let canvas = document.getElementById('gradient');
    let ctx = canvas.getContext('2d');
    this.gradient = null;
    this.gradient = ctx.createLinearGradient(70, 0, document.querySelector('canvas').width - 8, 0);

    this.gradient.addColorStop(0,     this.colors[0].hex);
    this.gradient.addColorStop(0.11,  this.colors[1].hex);
    this.gradient.addColorStop(0.22,  this.colors[2].hex);
    this.gradient.addColorStop(0.33,  this.colors[3].hex);
    this.gradient.addColorStop(0.44,  this.colors[4].hex);
    this.gradient.addColorStop(0.55,  this.colors[5].hex);
    this.gradient.addColorStop(0.66,  this.colors[6].hex);
    this.gradient.addColorStop(0.77,  this.colors[7].hex);
    this.gradient.addColorStop(0.88,  this.colors[8].hex);
    this.gradient.addColorStop(1,     this.colors[9].hex);

    return this.gradient;
  }

  updateZoomGradient(index)
  {
    let canvas = document.getElementById('gradient');
    let ctx = canvas.getContext('2d');

    let min = index === 0 ? 0 : index - 1;
    let max = index === 9 ? 9 : index + 1;

    this.zoomGradient = null;
    this.zoomGradient = ctx.createLinearGradient(70, 0, this.gradientWidth - 8, 0);
    this.zoomGradient.addColorStop(0,     this.colors[min].hex);
    if(index !== 0 && index !== 9) this.zoomGradient.addColorStop(0.5,   this.colors[index].hex);
    this.zoomGradient.addColorStop(1,     this.colors[max].hex);

    return this.zoomGradient;
  }
}

export default Family;
