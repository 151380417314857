import ColorEntity from "../entities/Color";
import FamilyEntity from "../entities/Family";

export function encodePaletteFromDataset(palette) {
  let output = Object.keys(palette).length + '.';

  Object.keys(palette).forEach((family) => {
    Object.keys(palette[family]).forEach((key) => {
      output += palette[family][key].substr(1)
    })
  });

  return output;
}

export function encodePalette(palette) {
  let output = palette.length + '.';

  palette.forEach((family) => {
    family.colors.forEach((color) => {
      output += color.hex.substr(1)
    })
  });

  return output;
}

export function decodePalette(code) {
  if(!code) return null;

  let colorsCode = code.split('.')[1];
  let familiesCount = parseInt(code.split('.')[0]);
  let carrier = 0;
  let output = [];

  if(colorsCode === undefined || familiesCount === 0 || isNaN(familiesCount) || colorsCode.length/familiesCount !== 60) return; // Validate code integrity

  for(let familyIndex = 0; familyIndex < familiesCount; familyIndex++){
    output[familyIndex] = new FamilyEntity(familyIndex, '', []);

    for(let colorIndex = 0; colorIndex < 10; colorIndex++){
      let hex = '#' + colorsCode.substring(carrier, carrier + 6);
      output[familyIndex].colors[colorIndex] = new ColorEntity(familyIndex, colorIndex, hex);
      carrier += 6;
    }

    output[familyIndex].datasetIndex = familyIndex;
    output[familyIndex].pickedColor = output[familyIndex].colors[5].hex;
    output[familyIndex].update();
  }

  return output;
}