module.exports = [
  { name: 'gray', hex: '#71717a' },
  {name: 'red', hex: '#e8027b'},
  {name: 'rose', hex: '#f2005d'},
  {name: 'ruby', hex: '#f20039'},
  {name: 'tomato', hex: '#f40003'},
  {name: 'carrot', hex: '#d44f00'},
  {name: 'orange', hex: '#bb6000'},
  {name: 'gold', hex: '#a27000'},
  {name: 'lemon', hex: '#8f7700'},
  {name: 'yellow', hex: '#758100'},
  {name: 'lime', hex: '#58860a'},
  {name: 'olive', hex: '#708238'},
  {name: 'grass', hex: '#2e8a0c'},
  {name: 'green', hex: '#0e8d37'},
  {name: 'emerald', hex: '#008e5c'},
  {name: 'turquoise', hex: '#008c6e'},
  {name: 'submarine', hex: '#00897d'},
  {name: 'leaf', hex: '#00878b'},
  {name: 'beach', hex: '#008696'},
  {name: 'island', hex: '#12849f'},
  {name: 'teal', hex: '#2683a6'},
  {name: 'navy', hex: '#3881ac'},
  {name: 'sea', hex: '#537fab'},
  {name: 'cyan', hex: '#5e79b0'},
  {name: 'blue', hex: '#7276ba'},
  {name: 'indigo', hex: '#896bc0'},
  {name: 'purple', hex: '#ab57c3'},
  {name: 'pink', hex: '#cd2dbd'},
  {name: 'violet', hex: '#e700a0'},

  {name: 'cerise', hex: '#DE3163'},
  {name: 'ochre', hex: '#CC7722'},
  {name: 'azure', hex: '#007FFF'},
  {name: 'puce', hex: '#CC8899'},
  {name: 'scarlet', hex: '#FF2400'},
  {name: 'chartreuse', hex: '#80FF00'},
  {name: 'pink', hex: '#EDAEB6'},
  {name: 'coffee', hex: '#6F4E37'},
  {name: 'vermilion', hex: '#BB0E1A'},
  {name: 'apricot', hex: '#F19E50'},
  {name: 'peach', hex: '#EE9E74'},
  {name: 'coral', hex: '#F25B48'},
  {name: 'mauve', hex: '#B280A2'},
  {name: 'lilac', hex: '#C39EC3'},
  {name: 'maroon', hex: '#580615'},
  {name: 'cerulean', hex: '#007BA7'},
  {name: 'khaki', hex: '#C3B091'},
  {name: 'beige', hex: '#F5F5DC'},
  {name: 'tan', hex: '#D2B48C'},
  {name: 'wood', hex: '#C19A6B'},
  {name: 'chocolate', hex: '#7B3F00'},
  {name: 'walnut', hex: '#5C5248'},
  {name: 'buff', hex: '#DAA06D'},
  {name: 'sand', hex: '#F4A460'},
  {name: 'russet', hex: '#80461B'},
  {name: 'cocoa', hex: '#D2691E'},
  {name: 'beaver', hex: '#9F8170'},
  {name: 'desert', hex: '#EDC9AF'},
  {name: 'topaz', hex: '#832A0D'},
  {name: 'chestnut', hex: '#954535'},
  {name: 'wenge', hex: '#645452'},
  {name: 'umber', hex: '#8A3324'},
  {name: 'viridian', hex: '#018361'},
  {name: 'plum', hex: '#653146'},
  {name: 'tangerine', hex: '#F28500'},
  {name: 'pumpkin', hex: '#FF7518'},
  {name: 'saffron', hex: '#F4C430'},
  {name: 'persimmon', hex: '#EC5800'},
];
