import chroma from 'chroma-js'
import Family from '../components/entities/Family'
import Color from '../components/entities/Color'

let familyColors = [];
let rgb = [];
let outputs = {};

function wrapper(fn) {
  return function shadesModel(input, id = 0, options) {
    familyColors = [];
    rgb = chroma(input).rgb().map(el => el/255);

    outputs = fn({
      r: rgb[0],
      g: rgb[1],
      b: rgb[2]
    });

    for(let i = 0; i < Object.keys(outputs).length/3; i++){
      familyColors.push(new Color(
        id,
        i.toString(),
        chroma([outputs['r' + i]*255, outputs['g' + i]*255, outputs['b' + i]*255]).hex(),
        chroma([outputs['r' + i]*255, outputs['g' + i]*255, outputs['b' + i]*255]).lch()
      ))
    }

    return new Family(id, 'tbd name', familyColors, options)
  };
}

export default wrapper;
