import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState, useRef } from 'react';
import Button from './Button'
import { ReactComponent as Clipboard } from './icons/clipboard.svg'
import { ReactComponent as ClipboardCheck } from './icons/clipboardCheck.svg'
import ReactGA from 'react-ga';
import colorNamer from '../color-namer/index'

function Code({ palette }) {
  const [copiedShareLink, setCopiedShareLink] = useState(false);

  let code = useRef(null);

  function copyCode() {
    setCopiedShareLink(true);
    setTimeout(() => { setCopiedShareLink(false) }, 1000);

    ReactGA.event({category: 'button', action: 'copy code', label: palette.length});
  }

  return (
    <div>
      <div className='absolute right-6 mt-2'>
        <CopyToClipboard text={code.current && code.current.innerText} onCopy={copyCode}>
          <Button className='text-indigo-50 px-2 py-1 text-sm'>
            <div className='flex'>
              <span>{ copiedShareLink ? <ClipboardCheck stroke='#ffffff' /> : <Clipboard stroke='#ffffff' /> }</span>
              <span>{ copiedShareLink ? 'Copied!' : 'Copy code' }</span>
            </div>
          </Button>
        </CopyToClipboard>
      </div>

      <pre className='text-xs p-3 bg-gray-800 text-gray-200 overflow-y-auto' style={{height: 'calc(100vh - 40px)'}}>
<code ref={code}>{`// tailwind.config.js
module.exports = {
  theme: {
    extend: {
      colors: {
        `}
  {palette.map(family =>
    `${colorNamer(family.colors[5].hex)}: {
          '50':  '${family.colors[0].hex}',
          '100': '${family.colors[1].hex}',
          '200': '${family.colors[2].hex}',
          '300': '${family.colors[3].hex}',
          '400': '${family.colors[4].hex}',
          '500': '${family.colors[5].hex}',
          '600': '${family.colors[6].hex}',
          '700': '${family.colors[7].hex}',
          '800': '${family.colors[8].hex}',
          '900': '${family.colors[9].hex}',
        },
        `)}
  {`
      }
    }
  }
}`}</code>
      </pre>
    </div>
  );
}

export default Code;
