import { InlineWidget } from "react-calendly";

function Masterclass() {

  return (
    <div>
      <InlineWidget styles={{height: '94vh'}} url="https://calendly.com/manceraio/color-palettes-for-ui-masterclass" />
    </div>
  );
}

export default Masterclass;
