function Color({ color, onMouseEnterColor, onMouseLeaveColor }) {
  return (
      <div className='pr-1'>
        <div
          data-tip
          data-for={color.hex} data-event='click focus'
          onMouseEnter={() => {onMouseEnterColor(color)}}
          onMouseLeave={() => {onMouseLeaveColor(color)}}
          className='w-7 sm:w-12 lg:w-10 xl:w-12 h-7 sm:h-12 lg:h-10 xl:h-12 rounded border-2 border-transparent'
          style={{backgroundColor: color.hex}}>
        </div>
      </div>
  );
}

export default Color;
