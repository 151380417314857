import colors from './colors'
import chroma from 'chroma-js'

function namer(hex) {

  colors.map((color) => {
    color.distance = chroma.distance(color.hex, hex, 'rgb');
    return color;
  });

  let sorted = Object.keys(colors).sort(function(a,b){
    return colors[a].distance - colors[b].distance
  });

  return colors[sorted[0]].name;
}

export default namer;