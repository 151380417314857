import { Line } from 'react-chartjs-2';

let options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  animation: false,
  onHover: function(e, d, chart) {
  },
  plugins: {
    tooltip: {
      mode: 'nearest',
      intersect: true,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      callbacks: {
        label: function(context) {
          return context.raw.toFixed(1);
        },
      }
    },
    annotation: {
      annotations: []
    },
    legend: {
      display: false,
    },
    title: {
      text: 'Contrast over White',
      display: true,
      position: 'left',
      color: 'black',
      font: {
        weight: 'bold',
        family: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      }
    },
    dragData: false,
  },
};

function WhiteContrast({ palette, contrastModel }) {
  let soloFamilies = palette.filter(family => family.solo === true).length;

  if(contrastModel === 'apca') {
    options.scales = {y: {max: 110, min: 0, ticks: {stepSize: 5}}}
    options.plugins.annotation.annotations = [
      {
        type: 'line',
        mode: 'horizontal',
        value: 45,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '45',
          position: 'start',
        }
      },{
        type: 'line',
        mode: 'horizontal',
        value: 60,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '60',
          position: 'start',
        }
      },{
        type: 'line',
        mode: 'horizontal',
        value: 75,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '75',
          position: 'start',
        }
      }
    ];
  }

  if(contrastModel === 'wcag') {
    options.scales = {y: {max: 20, min: 0, ticks: {stepSize: 1}}}
    options.plugins.annotation.annotations = [
      {
        type: 'line',
        mode: 'horizontal',
        value: 3,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '3',
          position: 'start',
        }
      },{
        type: 'line',
        mode: 'horizontal',
        value: 4.5,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '4.5',
          position: 'start',
        }
      },{
        type: 'line',
        mode: 'horizontal',
        value: 7,
        scaleID: 'y',
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          enabled: true,
          content: '7',
          position: 'start',
        }
      }
    ];
  }

  const data = canvas => {
    return {
      labels: ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'],
      datasets: palette.map((family) => {
        return {
          label: family.id,
          data: family.contrasts.overWhite,
          fill: false,
          pointRadius: 3,
          hoverRadius: 3,
          backgroundColor: family.solo || soloFamilies === 0 ? [...family.colors, {hex: '#000000'}].map((color) => {
            return color.hex
          }) : 'rgba(0, 0, 0, 0)',
          borderColor: family.solo || soloFamilies === 0 ? family.gradient : 'rgba(0, 0, 0, 0.1)',
        }
      })
    };
  };

  return (
    <div className='relative w-full' style={{height: '30vh'}}>
      <Line data={data} options={options} />
    </div>
  )
}

export default WhiteContrast;
