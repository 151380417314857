import { Link, useLocation } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from "react";
import Button from './Button';
import ReactGA from 'react-ga';

function NavRight({ convertPaletteToLinkData }) {
  const location = useLocation();
  const [copiedShareLink, setCopiedShareLink] = useState(false);

  function copyLink() {
    setCopiedShareLink(true);
    setTimeout(() => { setCopiedShareLink(false) }, 1000);

    ReactGA.event({category: 'button', action: 'copy link', label: convertPaletteToLinkData()});
  }

  return (
      <div className='mt-7 lg:mt-0'>
        <div className="flex justify-between px-4 border-b h-10">
          <div className="flex items-center text-gray-500 text-sm">
            <Link to='/'
                  className={`${location.pathname === '/' ? 'cursor-default border-b-2 border-blue-500 text-blue-500' : 'hover:text-gray-900' } mr-8 flex h-full focus:outline-none items-center justify-center`}>
              Graphs
            </Link>
            <Link to='/context'
                  className={`${location.pathname === '/context' ? 'cursor-default border-b-2 border-blue-500 text-blue-500' : 'hover:text-gray-900' } mr-8 flex h-full focus:outline-none items-center justify-center`}>
              Context
            </Link>
            <Link to='/code'
                  className={`${location.pathname === '/code' ? 'cursor-default border-b-2 border-blue-500 text-blue-500' : 'hover:text-gray-900' } mr-8 flex h-full focus:outline-none items-center justify-center`}>
              Code
            </Link>
            <Link to='/training'
                  className={`${location.pathname === '/training' ? 'cursor-default border-b-2 border-blue-500 text-blue-500' : 'hover:text-gray-900' } mr-8 flex h-full focus:outline-none items-center justify-center hidden sm:flex`}>
              <span>Training</span>
              <span className='text-xs ml-1 bg-gray-200 rounded-full px-1'>Expert</span>
            </Link>
            {/*<Link to='/masterclass'*/}
                  {/*className={`${location.pathname === '/masterclass' ? 'cursor-default border-b-2 border-blue-500 text-blue-500' : 'hover:text-gray-900' } mr-8 flex h-full focus:outline-none items-center justify-center`}>*/}
              {/*Masterclass*/}
            {/*</Link>*/}
          </div>
          <CopyToClipboard text={convertPaletteToLinkData()} onCopy={copyLink}>
            <Button type={ !copiedShareLink ? 'clipboard' : 'clipboardCheck' } text={ copiedShareLink ? 'Copied!' : 'Copy link' } className='my-1 px-2 text-sm hidden sm:flex' />
          </CopyToClipboard>
        </div>
      </div>
  );
}

export default NavRight;
