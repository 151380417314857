import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify';
import ReactGA from 'react-ga';

if(window.location.host !== 'localhost:3000') {
  Sentry.init({
    dsn: "https://0b4c6bef64bc4687b97b3ad932a00eb7@o366830.ingest.sentry.io/5871359",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
  console.log('Sentry ON')
}

function localStorageTest(){
  let test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
}

if(localStorageTest() === false) {
  ReactDOM.render('Tailwind.ink uses Web Storage for saving your color palettes locally, please enable it and refresh this page. How-to: https://mid.as/kb/00103/enable-disable-or-clear-web-storage-cache', document.getElementById('root'));
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <ToastContainer
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
        />
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true
  });
}

reportWebVitals(sendToAnalytics);
