import React from 'react'
import Select from 'react-select'

const colourStyles = {
  control: styles => ({ ...styles, fontSize: '0.875rem', fontWeight: '500', color: '#4b5563' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '0.875rem',
      fontWeight: '500',
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#e2f1f9'
          : isFocused
            ? '#f5f9f9'
            : null,
      color: '#4b5563',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && '#c0dff3',
      },
    };
  },
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '100%',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 11,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.19em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

function Dropdown({ onSelect, presetSelected, presets }) {
  let value = [...presets[0].options, ...presets[1].options].filter((preset) => {
    return preset.value === presetSelected
  });

  return (
    <Select
      className='z-20'
      value={value}
      options={presets}
      formatGroupLabel={formatGroupLabel}
      maxMenuHeight='600px'
      placeholder='Palettes'
      styles={colourStyles}
      onChange= {data => onSelect(data)}
    />
  )
}

export default Dropdown;