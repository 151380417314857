import stripe from '../../datasets/stripe'
import tailwindUi from '../../datasets/tailwindUi'
import tailwindNew from '../../datasets/tailwindNew'
import tailwindNewRevised from '../../datasets/tailwindNewRevised'
import ibm from '../../datasets/ibm'
// import namer from '../../datasets/namer'
// import namerPastel from '../../datasets/namerPastel'
// import namerAzure from '../../datasets/namerAzure'
// import namerAzure2 from '../../datasets/namerAzure2'
// import namerBeach from '../../datasets/namerBeach'
// import namerCarrot from '../../datasets/namerCarrot'
// import namerCarrot2 from '../../datasets/namerCarrot2'
// import namerCerise from '../../datasets/namerCerise'
// import namerCerise2 from '../../datasets/namerCerise2'
// import namerEmerald from '../../datasets/namerEmerald'
// import namerGold from '../../datasets/namerGold'
// import namerGreen from '../../datasets/namerGreen'
// import namerIndigo from '../../datasets/namerIndigo'
// import namerIsland from '../../datasets/namerIsland'
// import namerIsland2 from '../../datasets/namerIsland2'
// import namerLeaf from '../../datasets/namerLeaf'
// import namerLemon from '../../datasets/namerLemon'
// import namerLime from '../../datasets/namerLime'
// import namerLime2 from '../../datasets/namerLime2'
// import namerOrange from '../../datasets/namerOrange'
// import namerPink from '../../datasets/namerPink'
// import namerRed from '../../datasets/namerRed'
// import namerRose from '../../datasets/namerRose'
// import namerSubmarine from '../../datasets/namerSubmarine'
// import namerTeal from '../../datasets/namerTeal'
// import namerTurquoise from '../../datasets/namerTurquoise'
// import namerViolet from '../../datasets/namerViolet'
import namer from '../../datasets/namer'
import { encodePaletteFromDataset } from '../../components/utilities/palette';


const defaultPresets = [
  { value: 'tailwindUi',  label: 'Tailwind UI',  data: encodePaletteFromDataset(tailwindUi) },
  { value: 'tailwindNew', label: 'Tailwind New', data: encodePaletteFromDataset(tailwindNew) },
  { value: 'tailwindNewRevised', label: 'Tailwind New Revised', data: encodePaletteFromDataset(tailwindNewRevised) },
  { value: 'stripe',      label: 'Stripe',       data: encodePaletteFromDataset(stripe) },
  { value: 'ibm',         label: 'IBM',          data: encodePaletteFromDataset(ibm) },
  { value: 'lineal',       label: 'Lineal',      data: encodePaletteFromDataset(namer) },
  // { value: 'data 1', label: 'data 1', data: encodePaletteFromDataset(namerPastel) },
  // { value: 'data 2', label: 'data 2', data: encodePaletteFromDataset(namerAzure) },
  // { value: 'data 3', label: 'data 3', data: encodePaletteFromDataset(namerAzure2) },
  // { value: 'data 4', label: 'data 4', data: encodePaletteFromDataset(namerBeach) },
  // { value: 'data 5', label: 'data 5', data: encodePaletteFromDataset(namerCarrot) },
  // { value: 'data 6', label: 'data 6', data: encodePaletteFromDataset(namerCarrot2) },
  // { value: 'data 7', label: 'data 7', data: encodePaletteFromDataset(namerCerise) },
  // { value: 'data 8', label: 'data 8', data: encodePaletteFromDataset(namerCerise2) },
  // { value: 'data 9', label: 'data 9', data: encodePaletteFromDataset(namerEmerald) },
  // { value: 'data 0', label: 'data 0', data: encodePaletteFromDataset(namerGold) },
  // { value: 'data 12', label: 'data 12', data: encodePaletteFromDataset(namerGreen) },
  // { value: 'data 13', label: 'data 13', data: encodePaletteFromDataset(namerIndigo) },
  // { value: 'data 14', label: 'data 14', data: encodePaletteFromDataset(namerIsland) },
  // { value: 'data 15', label: 'data 15', data: encodePaletteFromDataset(namerIsland2) },
  // { value: 'data 16', label: 'data 16', data: encodePaletteFromDataset(namerLeaf) },
  // { value: 'data 17', label: 'data 17', data: encodePaletteFromDataset(namerLemon) },
  // { value: 'data 18', label: 'data 18', data: encodePaletteFromDataset(namerLime) },
  // { value: 'data 19', label: 'data 19', data: encodePaletteFromDataset(namerLime2) },
  // { value: 'data 20', label: 'data 20', data: encodePaletteFromDataset(namerOrange) },
  // { value: 'data 22', label: 'data 22', data: encodePaletteFromDataset(namerPink) },
  // { value: 'data 23', label: 'data 23', data: encodePaletteFromDataset(namerRed) },
  // { value: 'data 24', label: 'data 24', data: encodePaletteFromDataset(namerRose) },
  // { value: 'data 25', label: 'data 25', data: encodePaletteFromDataset(namerSubmarine) },
  // { value: 'data 26', label: 'data 26', data: encodePaletteFromDataset(namerTeal) },
  // { value: 'data 27', label: 'data 27', data: encodePaletteFromDataset(namerTurquoise) },
  // { value: 'data 28', label: 'data 28', data: encodePaletteFromDataset(namerViolet) },
//  { value: 'lineal pastel', label: '', data: encodePaletteFromDataset(namer) },
];

const customPresets = [
  { value: 'New', label: 'New', data: '1.f7f9fbe3f0fdc5d8fb9eb4f57d8cee6667e9544ade4038c02c269318185e' },
];

const groupedPresets = [
  {
    label: 'Default',
    options: defaultPresets,
  },
  {
    label: 'User',
    options: customPresets,
  },
];

export default groupedPresets;
