import Color from './Color'
import Button from './Button'
import { useState } from 'react'
import { ChromePicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as DuplicateIcon } from './icons/duplicate.svg'
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import chroma from 'chroma-js'
import ModelSelect from './ModelSelect'

function Family({ family, onDelete, palette, onSolo, onMouseEnterColor, onMouseLeaveColor, onColorChange, onRecalculateFamily, forceColorInFamily, onAverageHue, onDuplicate, onStandardLuminosity, onForceHue, onSmoothHue, onSmoothChroma, onPlusHue, onMinusHue, onMinusChroma, onPlusChroma }) {

  const [showXButtons, setShowXButtons] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [model, setModel] = useState();

  function notify(){
    toast('Copied!', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function toggleEditor() {
    setShowEditor(!showEditor);

    ReactGA.event({category: 'button', action: 'show family editor', label: !showEditor});
  }

  function onModelSelect(model) {
    setModel(model);

    ReactGA.event({category: 'button', action: 'select family model', label: model.value});
  }

  return (
    <div>
      <div className='flex mb-1 cursor-pointer' onMouseOver={() => {setShowXButtons(true)}} onMouseOut={() => {setShowXButtons(false)}}>
        <div className='mr-1 w-6'>
          <div className={`${showXButtons || family.solo || showEditor ? 'opacity-1 w-full justify-center' : 'opacity-0'} h-full flex flex-col justify-between items-stretch`}>
            <Button onClick={() => onSolo(family.id)} className={`${family.solo ? 'bg-blue-200 hover:bg-blue-300' : 'bg-white'} hidden sm:block w-full flex justify-center hover:bg-blue-50 text-xs flex-grow`}>s</Button>
            <Button onClick={() => {onDuplicate(family)}} className={`w-full flex justify-center hover:bg-blue-50 flex-grow`}>
              <DuplicateIcon height='15' width='15' strokeWidth='2.5'/>
            </Button>
          </div>
        </div>

        <div className='flex' onClick={toggleEditor}>
          {family.colors.map((color) => (
            <div key={color.id}>
              <Color
                color={color}
                onMouseEnterColor={onMouseEnterColor}
                onMouseLeaveColor={onMouseLeaveColor} />
            </div>
          ))}
        </div>

        <div className='w-8'>
          {palette.length > 1 &&
          <Button
            type='trash'
            onClick={() => onDelete(family.id)}
            className={`${showXButtons ? 'opacity-1 w-full justify-center' : 'opacity-0'} py-1`} />
          }
        </div>

      </div>
      { showEditor &&
        <div className={`ml-7 mb-4 pb-1 bg-gray-10 mr-8`} >
          <div className='flex text-code font-mono pt-1 pb-6 sm:pt-0 sm:pb-2 lg:pt-2 lg:pb-6 xl:pt-0 xl:pb-2'>
            {family.colors.map((color) => (
              <CopyToClipboard text={color.hex} key={color.id}>
                <div
                  className='w-7 sm:w-12 lg:w-10 xl:w-12 mr-1 transform rotate-45 sm:rotate-0 lg:rotate-45 xl:rotate-0 text-center text-gray-400 select-none cursor-pointer hover:shadow rounded'
                  style={{fontSize: '10px'}}
                  onClick={() => notify(color.hex)}>
                  {color.hex}
                </div>
              </CopyToClipboard>
            ))}
          </div>

          <div className='flex'>
            <div className='relative ml-1 border rounded-b-md' style={{width: '227px'}}>
              <ChromePicker
                color={ family.pickedColor }
                onChange={(color) => onColorChange(color, family, model)}
                onChangeComplete={(color) => ReactGA.event({category: 'button', action: 'color pick', label: color.hex})}
                disableAlpha={true} />

              <div className='flex items-center mb-2 ml-4'>
                <input
                  type='checkbox'
                  checked={family.forceColor}
                  onChange={() => {forceColorInFamily(family, !family.forceColor) }}/>
                <label className='text-xs text-gray-500 ml-1 select-none' onClick={() => {forceColorInFamily(family, !family.forceColor) }}>Force picked color</label>
              </div>

              {
                family.hasBeenDragged &&
                <div className='absolute top-0 w-full flex flex-col h-full justify-center items-center bg-white z-10 bg-opacity-95 rounded-b-md'>
                  <div className='text-xs text-gray-800 text-center mb-3 px-4 select-none'>The colors of this family has been adjusted individually. <br/> Recalculate color family?</div>
                  <Button onClick={() => onRecalculateFamily(family)} className='px-2 py-1 bg-white text-xs'>Enable picker</Button>
                </div>
              }
            </div>

            <div className='pl-2 w-56'>
              <div className='flex flex-col mt-2'>
                <label className='text-sm text-gray-700 font-medium pb-1 select-none'>Engine</label>
                <ModelSelect onModelSelect={onModelSelect} modelSelected={model} />
              </div>

              { window.location.host === 'localhost:3000' &&
                <div>
                  <div className='text-xs text-gray-500 tracking-wider my-1'>LUMINOSITY</div>
                  <div className='mb-2'>
                    <Button onClick={() => onStandardLuminosity(family, 'straight')} className='py-1 px-2 text-xs'>Std. Luminosity: Mid</Button>
                    <Button onClick={() => onStandardLuminosity(family, 'dark')} className='py-1 px-2 text-xs mt-2'>Std. Luminosity: Light</Button>
                    <Button onClick={() => onStandardLuminosity(family, 'light')} className='py-1 px-2 text-xs mt-2'>Std. Luminosity: Dark</Button>
                    {/*<Button onClick={() => onStandardLuminosity(family, 'str')} className='py-1 px-2 text-xs mt-2'>Std. Luminosity: Str</Button>*/}
                  </div>

                  <div className='text-xs text-gray-500 tracking-wider mb-1 mt-3'>CHROMA</div>
                  <div className='mb-2'>
                    <Button onClick={() => onSmoothChroma(family)} className='py-1 px-2 text-xs'>Smooth Chroma</Button>
                  </div>
                  <div className='mb-2 flex'>
                    <Button onClick={() => onMinusChroma(family)} className='py-1 px-2 text-xs mr-2'>- Chroma</Button>
                    <Button onClick={() => onPlusChroma(family)} className='py-1 px-2 text-xs'>+ Chroma</Button>
                  </div>

                  <div className='text-xs text-gray-500 tracking-wider mb-1 mt-3'>HUE</div>
                  <div className='mb-2'>
                    <Button onClick={() => onAverageHue(family)} className='py-1 px-2 text-xs'>Avg. Hue</Button>
                  </div>
                  <div className='mb-2'>
                    <Button onClick={() => onForceHue(family)} className='py-1 px-2 text-xs'>Force {Math.round(chroma(family.pickedColor).lch()[2])} Hue</Button>
                  </div>
                  <div className='mb-2'>
                    <Button onClick={() => onSmoothHue(family)} className='py-1 px-2 text-xs'>Smooth Hue</Button>
                  </div>
                  <div className='mb-2 flex'>
                    <Button onClick={() => onMinusHue(family)} className='py-1 px-2 text-xs mr-2'>- Hue</Button>
                    <Button onClick={() => onPlusHue(family)} className='py-1 px-2 text-xs'>+ Hue</Button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }

    </div>
  );
}

export default Family;
