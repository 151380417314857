
function Privacy() {

  return (
    <div className="m-8 text-gray-600">
      <h1 className="text-4xl">
        Privacy policy for iOS app
      </h1>

      <p className="my-4">
        Last updated: August 02, 2022
      </p>

      <p>
       The privacy of your data—and it is your data, not ours!—is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We promise we never sell your data: never have, never will.
      </p>

      <h2 className="text-2xl mt-8 mb-2">
        What we collect and why
      </h2>

      <p>
        Nothing. This app is so simple that we do not collect any user data.
      </p>

      <h2 className="text-2xl mt-8 mb-2">
        When we access or share your information
      </h2>

      <p>
       Never. As we don't have your data, we cannot access it.
      </p>

      <h2 className="text-2xl mt-8 mb-2">
        How we secure your data
      </h2>

      <p>
        We don't have any of your data, so it is as secure as it can be.
      </p>

      <h2 className="text-2xl mt-8 mb-2">
        Location of site and data
      </h2>

      <p>
        Our apps and other web properties are operated in Spain.
      </p>

      <h2 className="text-2xl mt-8 mb-2">
        Changes & questions
      </h2>

      <p>
        We may update this policy as needed to comply with relevant regulations and reflect any new practices.
        Whenever we make a significant change to our policies, we will refresh the date at the top of this page and take any other appropriate steps to notify users.
      </p>

      <p>
        Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at domingo@tailwind.ink and we’ll be happy to try to answer them!
      </p>
    </div>
  );
}

export default Privacy;
