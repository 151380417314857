import avatar from './images/Iliana.jpeg'

function MobileMockup({ dynamicColors }) {
  return (
    <div className='w-64 rounded-3xl shadow-lg' style={{width: '300px', backgroundColor: 'black'}}>
      <div className='p-5 flex'>
        <div className='flex items-center w-full'>
          <img src={avatar} alt="Logo" className='w-10 h-10 rounded-full -ml-1 mr-3' />
          <div style={{color: dynamicColors[0][2].hex}}>
            <div className='text-lg font-bold'>Hi, Sherry</div>
            <div className='text-sm -mt-1' style={{color: dynamicColors[0][4].hex}}>How are you doing?</div>
          </div>
          <div className='flex-grow flex justify-end'>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][2].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"/>
              <path d="M9 17v1a3 3 0 0 0 6 0v-1"/>
            </svg>
          </div>
        </div>
      </div>

      <div className='bg-white rounded-3xl pt-5' style={{backgroundColor: dynamicColors[0][9].hex}}>
        <div className='flex justify-between px-5'>
          <div className='font-bold mb-3' style={{color: dynamicColors[0][3].hex}}>Projects</div>
          <div className='text-xs mt-1' style={{color: dynamicColors[0][3].hex}}>4/10 done</div>
        </div>
        <div className='flex justify-between px-5'>
          <div className='flex-grow mr-1'>
            <div className='rounded-xl p-3 mb-2' style={{backgroundColor: dynamicColors[1][8].hex}}>
              <div className='p-2 rounded-full inline-block' style={{backgroundColor: dynamicColors[1][6].hex}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="20"
                     height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke={dynamicColors[1][2].hex} fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <circle cx="12" cy="7" r="4"/>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
                </svg>
              </div>
              <div className='text-sm' style={{color: dynamicColors[1][1].hex}}>Personal</div>
              <div className='bg-white text-xs border px-1 inline-block rounded' style={{color: dynamicColors[1][2].hex, borderColor: dynamicColors[1][2].hex, backgroundColor: dynamicColors[1][6].hex}}>2/10</div>
            </div>
            <div className='rounded-xl p-3'  style={{backgroundColor: dynamicColors[2][7].hex}}>
              <div className='text-sm' style={{color: dynamicColors[2][1].hex}}>Work</div>
              <div className='text-xs' style={{color: dynamicColors[2][2].hex}}>09-May</div>
              <div className='flex items-center'>
                <div className='mr-2 h-1 w-full flex'>
                  <div className='w-2/3 h-1 rounded-l-full' style={{backgroundColor: dynamicColors[2][5].hex}}></div>
                  <div className='w-1/3 h-1 rounded-r-full' style={{backgroundColor: dynamicColors[2][9].hex}}></div>
                </div>
                <div className='text-xs' style={{color: dynamicColors[2][3].hex}}>67%</div>
              </div>
            </div>
          </div>
          <div className='flex-grow ml-1'>
            <div className='rounded-xl p-3 mb-2' style={{backgroundColor: dynamicColors[4][7].hex}}>
              <div className='mb-2 text-sm' style={{color: dynamicColors[4][1].hex}}>Meditation</div>
              <div className='bg-gray-400 inline-block rounded-full px-2 py-1 text-sm' style={{backgroundColor: dynamicColors[4][6].hex, color: dynamicColors[4][1].hex}}>1 left</div>
            </div>
            <div className='rounded-xl p-3 flex items-center'  style={{backgroundColor: dynamicColors[3][8].hex}}>
              <div className='mr-1'>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folder" width="20"
                     height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[3][2].hex} fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2"/>
                </svg>
              </div>
              <div className='text-sm' style={{color: dynamicColors[3][1].hex}}>Travel</div>
            </div>
          </div>
        </div>
        <div className='flex justify-between px-5 mt-4 mb-2 items-center'>
          <div className='font-bold' style={{color: dynamicColors[0][3].hex}}>Tasks</div>
          <div className='text-xs px-2 py-1 border font-medium rounded-full' style={{color: dynamicColors[1][3].hex, backgroundColor: dynamicColors[1][8].hex, borderColor: dynamicColors[1][6].hex}}>1/2 done</div>
        </div>
        <div className='px-5 mb-2'>
          <div className='text-sm border rounded-lg px-3 py-2 flex justify-between' style={{color: dynamicColors[0][3].hex, borderColor: dynamicColors[0][4].hex}}>
            <div>Learn Spanish</div>
            <div className='rounded-full' style={{backgroundColor: dynamicColors[0][8].hex}}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check"
                   width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][3].hex} fill="none"
                   strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9"/>
                <path d="M9 12l2 2l4 -4"/>
              </svg>
            </div>
          </div>
        </div>
        <div className='px-5'>
          <div className='text-sm rounded-lg px-3 py-2 flex justify-between' style={{backgroundColor: dynamicColors[0][8].hex, color: dynamicColors[0][2].hex}}>
            <div>Go for a walk</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle" width="20"
                   height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][4].hex} fill={dynamicColors[0][2].hex}
                   strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9"/>
              </svg>
            </div>
          </div>
        </div>
        <div className='mx-5'>
          <div className='mt-4 px-4 py-3 rounded-xl text-gray-100 text-center' style={{color: dynamicColors[0][9].hex, backgroundColor: dynamicColors[0][3].hex}}>Add New Task</div>
        </div>
        <div className='mt-4 bg-gray-10 flex justify-evenly border-t items-center py-4' style={{borderColor: dynamicColors[0][4].hex}}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-smart-home" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][5].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path
                d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105"/>
              <path d="M16 15c-2.21 1.333 -5.792 1.333 -8 0"/>
            </svg>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bookmarks" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][3].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z"/>
              <path d="M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6"/>
            </svg>
          </div>
          <div className='p-2 rounded-lg' style={{backgroundColor: dynamicColors[0][5].hex}}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][1].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="12" cy="12" r="9"/>
              <line x1="9" y1="12" x2="15" y2="12"/>
              <line x1="12" y1="9" x2="12" y2="15"/>
            </svg>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][3].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="10" cy="10" r="7"/>
              <line x1="21" y1="21" x2="15" y2="15"/>
            </svg>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="20"
                 height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={dynamicColors[0][3].hex} fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path
                d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"/>
              <circle cx="12" cy="12" r="3"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMockup;
