import Family from './Family'
import Button from './Button'
import { useState } from 'react'

function Palette({ palette, onDeleteFamily, onHorizontalZoomClick, zoom, onSolo, onMouseEnterColor, onMouseLeaveColor, className, onColorChange, onRecalculateFamily, forceColorInFamily, onAverageHue, onDuplicateFamily, onStandardLuminosity, onForceHue, onSmoothHue, onSmoothChroma, onPlusHue, onMinusHue, onPlusChroma, onMinusChroma }){

  const [hoveredColor, setHoveredColor] = useState({index: null});

  function onMouseEnter(color){
    onMouseEnterColor(color);
    setHoveredColor(color);
  }

  function onMouseLeave(color){
    onMouseLeaveColor(color);
    setHoveredColor({index: null});
  }

  return (
    <div className={className}>
      <div className='flex mb-1 mr-8 ml-7'>
        {palette[0] && palette[0].colors.map((color, index) =>
          <div className='w-7 sm:w-12 lg:w-10 xl:w-12 mr-1' key={color.id} onMouseEnter={() => {onMouseEnter(color)}}>
            <Button
              className={`${index === hoveredColor.index || (zoom.x.active && zoom.x.index === color.index) ? 'opacity-1 w-full justify-center' : 'opacity-0'} py-1`}
              type={zoom.x.active && zoom.x.index === color.index ? 'zoomOut' : 'zoomIn'}
              onClick={() => onHorizontalZoomClick(color.index)} />
          </div>
        )}
      </div>

      {palette.map((family) => (
        <Family
          key={family.id}
          family={family}
          onDelete={onDeleteFamily}
          onSolo={onSolo}
          palette={palette}
          onMouseEnterColor={onMouseEnter}
          onMouseLeaveColor={onMouseLeave}
          onColorChange={onColorChange}
          onRecalculateFamily={onRecalculateFamily}
          forceColorInFamily={forceColorInFamily}
          onAverageHue={onAverageHue}
          onDuplicate={onDuplicateFamily}
          onStandardLuminosity={onStandardLuminosity}
          onForceHue={onForceHue}
          onSmoothHue={onSmoothHue}
          onSmoothChroma={onSmoothChroma}
          onPlusHue={onPlusHue}
          onMinusHue={onMinusHue}
          onMinusChroma={onMinusChroma}
          onPlusChroma={onPlusChroma}
        />
      ))}
    </div>
  );
}

export default Palette;