import chroma from 'chroma-js'
import Family from '../components/entities/Family'
import Color from '../components/entities/Color'
import * as tf from '@tensorflow/tfjs'

let familyColors = [];
let outputs = {};

function predictedToChromaColor(predicted) {
  let predictedColors = [];
  let singleColor = [];

  predicted.forEach((value, index) => {
    singleColor.push(value);
    if(index%3 === 2) {
      predictedColors.push(chroma(singleColor[0]*255, singleColor[1]*255, singleColor[2]*255, 'rgb'));
      singleColor = [];
    }
  });

  return predictedColors;
}

export default async function shadesModel() {
  if (localStorage.getItem('tensorflowjs_models/my-model/weight_data') === null) {
    return null;
  }

  const model = await tf.loadLayersModel('localstorage://my-model');

  function familyModel(input = '#ff0000', id = 0, options) {
    let pickedColorLch = chroma(input).rgb();

    familyColors = [];

    outputs = predictedToChromaColor(model.predict(tf.tensor([[pickedColorLch[0]/255, pickedColorLch[1]/255, pickedColorLch[2]/255]])).dataSync());
    outputs.forEach((output, key) => {
      familyColors.push(new Color(
        id,
        key,
        output.hex(),
        output.lch()
      ))
    });

    return new Family(id, 'tbd name', familyColors, options)
  }

  return familyModel;
};
