import { Line } from 'react-chartjs-2';

let options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  animation: false,
  elements: {
    line: {
      fill: false,
    },
    point: {
      borderWidth: 0,
      hoverBorderWidth: 0,
      hitRadius: 0
    }
  },
  plugins: {
    tooltip: {
      mode: 'nearest',
      intersect: false,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      callbacks: {
        label: function(context) {
          return context.raw.toFixed(1);
        },
      }
    },
    legend: {
      display: false,
    },
    title: {
      text: 'Contrast Pairs',
      display: true,
      position: 'left',
      color: 'black',
      font: {
        weight: 'bold',
        family: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      }
    },
    dragData: false,
    annotation: {
      annotations: []
    }
  },
};

function PairContrast({ palette, contrastModel }) {
  let soloFamilies = palette.filter(family => family.solo === true).length;

  if(contrastModel === 'apca') {
    options.scales = {y: {max: 20, min: 0, ticks: {stepSize: 1}}}
    options.plugins.annotation.annotations = [];
  }

  if(contrastModel === 'wcag') {
    options.scales = {y: {max: 2, min: 1, ticks: {stepSize: 0.1}}}
    options.plugins.annotation.annotations = [
      // {
      //   type: 'box',
      //   yMin: 1,
      //   yMax: 1.3,
      //   xMin: 3,
      //   xMax: 4,
      //   backgroundColor: 'rgba(0, 0, 0, 0.3)',
      //   borderWidth: 0
      // },
      // {
      //   type: 'box',
      //   yMin: 1,
      //   yMax: 1.4,
      //   xMin: 4,
      //   xMax: 8,
      //   backgroundColor: 'rgba(0, 0, 0, 0.3)',
      //   borderWidth: 0
      // },
      // {
      //   type: 'box',
      //   yMin: 1,
      //   yMax: 1.2,
      //   xMin: 8,
      //   xMax: 10,
      //   backgroundColor: 'rgba(0, 0, 0, 0.3)',
      //   borderWidth: 0
      // }
    ]
  }

  const data = canvas => {
    return {
      labels: ['White-50', '50-100', '100-200', '200-300', '300-400', '400-500', '500-600', '600-700', '700-800', '800-900', '900-Black'],
      datasets: palette.map((family) => {
        return {
          label: family.id,
          data: family.contrasts.pairs,
          fill: false,
          pointRadius: 3,
          hoverRadius: 3,
          backgroundColor: family.solo || soloFamilies === 0 ? [...family.colors, {hex: '#000000'}].map((color) => {
            return color.hex
          }) : 'rgba(0, 0, 0, 0)',
          borderColor: family.solo || soloFamilies === 0 ? family.gradient : 'rgba(0, 0, 0, 0.1)',
        }
      })
    };
  };

  return (
    <div className='relative w-full' style={{height: 'calc(30vh - 27px)'}}>
      <Line data={data} options={options} />
    </div>
  )
}

export default PairContrast;
