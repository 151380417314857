let colors = {
  red: {
    50:  '#fff1f1',
    100: '#ffd7d9',
    200: '#ffb3b8',
    300: '#ff8389',
    400: '#fa4d56',
    500: '#da1e28',
    600: '#a2191f',
    700: '#750e13',
    800: '#520408',
    900: '#2d0709',
  },
  pink: {
    50:  '#fff0f7',
    100: '#ffd6e8',
    200: '#ffafd2',
    300: '#ff7eb6',
    400: '#ee5396',
    500: '#d02670',
    600: '#9f1853',
    700: '#740937',
    800: '#510224',
    900: '#2a0a18',
  },
  purple: {
    50:  '#f6f2ff',
    100: '#e8daff',
    200: '#d4bbff',
    300: '#be95ff',
    400: '#a56eff',
    500: '#8a3ffc',
    600: '#6929c4',
    700: '#491d8b',
    800: '#31135e',
    900: '#1c0f30',
  },
  blue: {
    900: '#001141',
    800: '#001d6c',
    700: '#002d9c',
    600: '#0043ce',
    500: '#0f62fe',
    400: '#4589ff',
    300: '#78a9ff',
    200: '#a6c8ff',
    100: '#d0e2ff',
    50:  '#edf5ff',
  },
  cyan: {
    900: '#061727',
    800: '#012749',
    700: '#003a6d',
    600: '#00539a',
    500: '#0072c3',
    400: '#1192e8',
    300: '#33b1ff',
    200: '#82cfff',
    100: '#bae6ff',
    50:  '#e5f6ff',
  },
  teal: {
    900: '#081a1c',
    800: '#022b30',
    700: '#004144',
    600: '#005d5d',
    500: '#007d79',
    400: '#009d9a',
    300: '#08bdba',
    200: '#3ddbd9',
    100: '#9ef0f0',
    50:  '#d9fbfb',
  },
  green: {
    900: '#071908',
    800: '#022d0d',
    700: '#044317',
    600: '#0e6027',
    500: '#198038',
    400: '#24a148',
    300: '#42be65',
    200: '#6fdc8c',
    100: '#a7f0ba',
    50:  '#defbe6',
  },
  gray: {
    900: '#121619',
    800: '#21272a',
    700: '#343a3f',
    600: '#4d5358',
    500: '#697077',
    400: '#878d96',
    300: '#a2a9b0',
    200: '#c1c7cd',
    100: '#dde1e6',
    50:  '#f2f4f8',
  },
  gray1: {
    900: '#161616',
    800: '#262626',
    700: '#393939',
    600: '#525252',
    500: '#6f6f6f',
    400: '#8d8d8d',
    300: '#a8a8a8',
    200: '#c6c6c6',
    100: '#e0e0e0',
    50:  '#f4f4f4',
  },
  gray2: {
    900: '#171414',
    800: '#272525',
    700: '#3c3838',
    600: '#565151',
    500: '#726e6e',
    400: '#8f8b8b',
    300: '#ada8a8',
    200: '#cac5c4',
    100: '#e5e0df',
    50:  '#f7f3f2',
  }
};

module.exports = colors;